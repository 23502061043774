<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="위험물 및 지정수량"
      tableId="chemprod-inout"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      :usePaging="false"
      @table-data-change="tableDataChange"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="save"
            @btnCallback="saveCallback"/>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'danger-item',
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
      },
      grid: {
        columns: [],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      regulatedItems: [],
      editable: true,
      columnUrl: '',
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.columnUrl = selectConfig.mdm.mam.regulation.item.list.url;
      this.listUrl = selectConfig.chm.base.dangerItem.list.url;
      this.saveUrl = transactionConfig.chm.base.dangerItem.save.url
      // code setting
      // list setting
      this.setColumns();
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    setColumns() {
      this.$http.url = this.columnUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        regulationBillCd: 'RBC0000012', // 위험물안전관리법
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        this.regulatedItems = _result.data
        this.grid.columns = [
          {
            name: 'danger',
            field: 'danger',
            label: '위험물',
            child: [
              {
                name: 'regulatedItemCd',
                field: 'regulatedItemCd',
                label: '유별',
                align: 'center',
                sortable: true,
                style: 'width:100px',
                type: 'select',
                itemText: 'regulatedItemName',
                itemValue: 'regulatedItemCd',
                comboItems: this.regulatedItems,
                required: true,
              },
              {
                name: 'regulationDesc',
                field: 'regulationDesc',
                label: '성질',
                align: 'center',
                sortable: true,
                style: 'width:100px',
              },
              {
                name: 'dangerItemName',
                field: 'dangerItemName',
                label: '품명',
                align: 'left',
                sortable: true,
                style: 'width:200px',
                type: 'text',
                required: true,
              },
            ]
          },
          {
            name: 'designatedQuantity',
            field: 'designatedQuantity',
            label: '지정수량',
            align: 'left',
            sortable: true,
            style: 'width:150px',
            type: 'text',
            required: true,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            style: 'width:90px',
            sortable: false,
            type: 'number',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'check',
            true: 'Y',
            false: 'N',
          },
        ]
      },);
    },
    tableDataChange(props, col) {
      if (col.name === 'regulatedItemCd') {
        let data = this.$_.find(this.regulatedItems, { regulatedItemCd: props.row[col.name] });
        if (data) {
          this.$set(props.row, 'regulatedItemName', data.regulatedItemName)
          this.$set(props.row, 'regulationDesc', data.regulationDesc)
        }
      }
    },
    add() {
      if (!this.grid.data) {
        this.grid.data = [];
      }
      this.grid.data.splice(0, 0, {
        chmDangerItemId: '',  // 위험물 품목 번호
        regulatedItemCd: null,  // 규제항목 코드
        regulatedItemName: '',  // 규제항목 명
        dangerItemName: '',  // 위험물 품목명
        designatedQuantity: '',  // 지정수량
        sortOrder: null,  // 정렬순서
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    save() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
